<template>
  <v-container class="mkt-content-container mt-10">
    <px-compare-view-header :ofertas-fretes="ofertasFretes"/>
    <v-card class="rounded-card card-shadow">
      <v-card-text class="text--primary">
        <v-skeleton-loader
          :loading="loading"
          type="table"
        >
          <px-compare-table :ofertas-comparacao="ofertasComparacao"
                            :comprador="comprador"
                            @change="onChange"
                            @loaded="onLoadFrete"/>
        </v-skeleton-loader>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  LOAD_INITIAL_STATE_ACT,
} from '@/store/compare/actions.type';
import { sortBy } from 'lodash';
import { EventTypes } from 'px-business-components';
import CompradorService from '@/api/comprador-service';
import axios from 'axios';
import EventService from '@/events';
import OfertaService from '../ofertas/oferta-service';
import CompareTable from './CompareTable.vue';
import CompareViewHeader from './CompareViewHeader.vue';

const LIMIT = 15;

export default {
  data() {
    return {
      loading: false,
      ofertasComparacao: [],
      comprador: null,
      ofertasFretes: [],
    };
  },
  computed: {
    ...mapGetters('compare', ['ofertas', 'oferta']),
    ...mapGetters(['isLogged']),
  },
  methods: {
    ...mapActions('compare', {
      loadInitialState: LOAD_INITIAL_STATE_ACT,
    }),
    buildQuery() {
      const ids = this.ofertas.map(oferta => oferta.id);
      return `id in (${ids.join(',')})`;
    },
    getOfertas() {
      this.loading = true;
      OfertaService.queryPagamentos({
        limit: LIMIT,
        offset: 0,
        query: this.buildQuery(),
      })
        .then(({ data }) => {
          this.ofertasComparacao = sortBy(data, 'oferta.precoAVista');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getComprador() {
      this.loading = true;
      return CompradorService.getComprador()
        .then(({ data }) => {
          [this.comprador] = data;
          return this.getOfertas();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onLoadFrete(frete) {
      this.ofertasFretes.push(frete);
    },
    onChange() {
      this.init();
    },
    init() {
      if (this.isLogged) {
        this.getComprador();
        return;
      }
      this.getOfertas();
    },
    fireEvent() {
      const ids = this.ofertas.map(oferta => oferta.id);
      EventService.createAcesso(axios, EventTypes.Resource.COMPARE_VIEW, { ids });
    },
  },
  created() {
    this.loadInitialState();
    if (!this.ofertas.length) {
      this.$router.push({ name: 'public.home' });
      return;
    }
    this.fireEvent();
    this.init();
  },
  components: {
    pxCompareTable: CompareTable,
    pxCompareViewHeader: CompareViewHeader,
  },
};
</script>
