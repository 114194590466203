<template>
  <tr>
    <td>Forma de pagamento</td>
    <td v-for="ofertaItem in ofertasComparacao" :key="ofertaItem.id"
        class="top text-center">
      <div class="pt-3 pb-3">
        <div>
          Boleto ou Pix <b>à vista</b>
        </div>
        <div class="font-weight-bold mt-3"
             v-if="ofertaItem.prazosPagamentos && ofertaItem.prazosPagamentos.length">
          Pacelamento no boleto:
        </div>
        <div v-for="formaPagamento in ofertaItem.prazosPagamentos"
             :key="formaPagamento.id" class="mt-1">
          + {{ formaPagamento.valorMinimo | currency }}
          em
          <b>{{ formaPagamento.prazoPagamento.descricao }}</b> dias
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    ofertasComparacao: {
      type: Array,
      required: true,
    },
  },
};
</script>
