<template>
  <v-row>
    <v-col cols="12" class="text-right">
      <v-btn v-if="isLogged" outlined
             :color="sendBtnColor"
             class="rounded-tag-small normal-btn-text mr-3"
             :loading="loadingSend"
             :disabled="!ofertasFretes || !ofertasFretes.length"
             @click="onClickMail">
        <v-icon left>{{ sendBtnIcon }}</v-icon>
        {{ sendBtnText }}
      </v-btn>
      <v-btn outlined
             class="rounded-tag-small normal-btn-text"
             @click="onClickClose">
        <v-icon left>mdi-close</v-icon>
        Fechar comparação
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  CLEAR_OFERTAS_ACT,
} from '@/store/compare/actions.type';
import EventService from '@/events';
import axios from 'axios';
import { EventTypes } from 'px-business-components';
import CompareService from './compare-service';

export default {
  props: {
    ofertasFretes: Array,
  },
  data() {
    return {
      loadingSend: false,
      sended: false,
    };
  },
  computed: {
    ...mapGetters(['isLogged']),
    ...mapGetters('compare', ['ofertas']),
    sendBtnColor() {
      if (this.sended) {
        return 'success';
      }
      return 'primary';
    },
    sendBtnIcon() {
      if (this.sended) {
        return 'mdi-check';
      }
      return 'mdi-email-outline';
    },
    sendBtnText() {
      if (this.sended) {
        return 'E-mail enviado';
      }
      return 'Receber por e-mail';
    },
  },
  methods: {
    ...mapActions('compare', {
      clearOfertas: CLEAR_OFERTAS_ACT,
    }),
    onClickClose() {
      this.clearOfertas();
      this.$router.push({ name: 'public.home' });
    },
    onClickMail() {
      if (this.sended) {
        return;
      }
      this.loadingSend = true;
      CompareService.sendEmail({
        ofertas: this.ofertasFretes,
      })
        .then(() => {
          this.$toast('Em breve você receberá um e-mail com as ofertas selecionadas',
            { color: 'success' });
          this.sended = true;
          this.fireEvent();
        })
        .finally(() => {
          this.loadingSend = false;
        });
    },
    fireEvent() {
      const ids = this.ofertasFretes.map(oferta => oferta.id);
      EventService.createAcesso(axios, EventTypes.Resource.COMPARE_VIEW_EMAIL, { ids });
    },
  },
};
</script>
