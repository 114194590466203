<template>
  <tr>
    <td>Promoção</td>
    <td v-for="ofertaItem in ofertasComparacao" :key="ofertaItem.oferta.id"
        class="text-center">
      <span v-if="ofertaItem.oferta.promocaoAtiva && desconto(ofertaItem.oferta) > 0">
        {{ desconto(ofertaItem.oferta) }}%
      </span>
      <span v-else>
        <v-icon color="error">mdi-close</v-icon>
      </span>
    </td>
  </tr>
</template>

<script>
import currency from 'currency.js';

export default {
  props: {
    ofertasComparacao: {
      type: Array,
      required: true,
    },
  },
  computed: {
    desconto() {
      return (oferta) => {
        if (!oferta.promocao) {
          return 0;
        }
        const desconto = currency(oferta.promocao.percentualDesconto)
          .multiply(100)
          .value;
        if (desconto < 0) {
          return 0;
        }
        return Math.round(desconto);
      };
    },
  },
};
</script>
