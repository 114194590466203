<template>
  <tr>
    <td>Fornecedor</td>
    <td v-for="ofertaItem in ofertasComparacao" :key="ofertaItem.oferta.id" class="text-center">
      <div class="large-text pt-3 pb-3">
        <router-link :to="`/fornecedor/${ofertaItem.oferta.fornecedor.id}`" target="_blank">
          {{ ofertaItem.oferta.fornecedor.pessoa.nome }}
        </router-link>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    ofertasComparacao: {
      type: Array,
      required: true,
    },
  },
};
</script>
