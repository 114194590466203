<template>
  <tr class="non-selectable-tr">
    <td class="text-center" :colspan="colspanLoginRedirect">
      <px-go-to-login-block>
        <template v-slot:activator="{ on }">
                <span class="body-2">
                  <a v-on="on">Faça login</a> para ver a tabela de comparação completa
                </span>
        </template>
      </px-go-to-login-block>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    ofertasComparacao: {
      type: Array,
      required: true,
    },
  },
  computed: {
    colspanLoginRedirect() {
      return this.ofertasComparacao.length + 1;
    },
  },
};
</script>
