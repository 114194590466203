<template>
  <v-simple-table>
    <template v-slot:default>
      <tbody>
      <px-compare-table-photo-row :ofertas-comparacao="ofertasComparacao"
                                  @change="onChange"/>
      <tr>
        <td>Oferta</td>
        <td v-for="ofertaItem in ofertasComparacao" :key="ofertaItem.oferta.id"
            class="text-center pt-3 pb-3" style="max-width: 200px">
          <router-link :to="`/oferta/${ofertaItem.oferta.id}`" target="_blank">
            {{ ofertaItem.oferta.titulo }}
          </router-link>
        </td>
      </tr>
      <px-compare-table-price-row :is-a-vista="true" :ofertas-comparacao="ofertasComparacao"/>
      <px-compare-table-price-row :is-a-vista="false"
                                  :ofertas-comparacao="ofertasComparacao"
                                  row-title="Preço a prazo"/>
      <template v-if="isLogged">
        <px-compare-table-desconto :ofertas-comparacao="ofertasComparacao"/>
        <px-compare-table-ipi :ofertas-comparacao="ofertasComparacao"/>
        <tr>
          <td>Quantidade mínima</td>
          <td v-for="ofertaItem in ofertasComparacao" :key="ofertaItem.oferta.id"
              class="text-center">
            {{ ofertaItem.oferta.quantidade }} {{ ofertaItem.oferta.unidadeMedida.descricao }}
          </td>
        </tr>
        <tr>
          <td>Unidade de medida</td>
          <td v-for="ofertaItem in ofertasComparacao" :key="ofertaItem.oferta.id"
              class="text-center">
            {{ ofertaItem.oferta.unidadeMedida.descricao }}
          </td>
        </tr>
        <px-compare-table-flag-row flag-value="possuiDescontos"
                                   :ofertas-comparacao="ofertasComparacao"
                                   row-title="Desconto progressivo">
          <template v-slot:column>
            <px-menu-info
              info-text="Quanto mais itens sua emprea compra, maior o desconto aplicado"/>
          </template>
        </px-compare-table-flag-row>
        <px-compare-table-fornecedor :ofertas-comparacao="ofertasComparacao"/>
        <px-compare-table-frete :ofertas-comparacao="ofertasComparacao"
                                :comprador="comprador"
                                @loaded="onLoadFrete"/>
        <px-compare-table-forma-pagamento :ofertas-comparacao="ofertasComparacao"/>
        <px-compare-table-especificacao :ofertas-comparacao="ofertasComparacao"/>
      </template>
      <template v-else>
        <px-compare-table-non-logged :ofertas-comparacao="ofertasComparacao"/>
      </template>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapGetters } from 'vuex';
import CompareTablePhotoRow from './CompareTablePhotoRow.vue';
import CompareTablePriceRow from './CompareTablePriceRow.vue';
import CompareTableFlagRow from './CompareTableFlagRow.vue';
import CompareTableDesconto from './CompareTableDesconto.vue';
import CompareTableIpi from './CompareTableIpi.vue';
import CompareTableFrete from './CompareTableFrete.vue';
import CompareTableFormaPagamento from './CompareTableFormaPagamento.vue';
import CompareTableEspecificacao from './CompareTableEspecificacao.vue';
import CompareTableNonLogged from './CompareTableNonLogged.vue';
import CompareTableFornecedor from './CompareTableFornecedor.vue';

export default {
  props: {
    ofertasComparacao: {
      type: Array,
      required: true,
    },
    comprador: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(['isLogged']),
  },
  methods: {
    onLoadFrete(frete) {
      this.$emit('loaded', frete);
    },
    onChange() {
      this.$emit('change');
    },
  },
  components: {
    pxCompareTablePhotoRow: CompareTablePhotoRow,
    pxCompareTablePriceRow: CompareTablePriceRow,
    pxCompareTableFlagRow: CompareTableFlagRow,
    pxCompareTableDesconto: CompareTableDesconto,
    pxCompareTableFrete: CompareTableFrete,
    pxCompareTableFormaPagamento: CompareTableFormaPagamento,
    pxCompareTableEspecificacao: CompareTableEspecificacao,
    pxCompareTableNonLogged: CompareTableNonLogged,
    pxCompareTableFornecedor: CompareTableFornecedor,
    pxCompareTableIpi: CompareTableIpi,
  },
};
</script>
