<template>
  <tr>
    <td>IPI</td>
    <td v-for="ofertaItem in ofertasComparacao" :key="ofertaItem.oferta.id"
        class="text-center">
      <span v-if="hasIPI(ofertaItem)">
        {{ percentual(ofertaItem) | number('0.00') }}% de IPI
        <b>{{ aplicacaoIpi(ofertaItem) }}</b>
      </span>
      <span v-else>
        -
      </span>
    </td>
  </tr>
</template>

<script>
import { NumberUtils } from 'px-components';

export default {
  props: {
    ofertasComparacao: {
      type: Array,
      required: true,
    },
  },
  computed: {
    percentual() {
      return ofertaItem => NumberUtils
        .fixNumberInput(ofertaItem.oferta.ipi.percentualImposto, true);
    },
    hasIPI() {
      return ofertaItem => ofertaItem.oferta.ipi && ofertaItem.oferta.ipi.destacar;
    },
    aplicacaoIpi() {
      return (ofertaItem) => {
        if (ofertaItem.oferta.ipi.tipoAplicacao === 'INCLUSO') {
          return 'incluso';
        }
        return 'não incluso';
      };
    },
  },
};
</script>
